'use strict';

angular.module('enervexSalesappApp').controller('ProductSubtypeCtrl', function($scope, ProductSubtype, ProductType, $stateParams, Flash, $state, _, Util, Application) {
	$scope.errors = {};
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.ctx ={}
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	if ($stateParams.id == "new") {
		$scope.item = {
			name: "",
			code:"",
			productTypes:[]
		}
		fetchDeps()
		$scope.itemCodeDisabled = false
	} else {
		ProductSubtype.get({
			id: $stateParams.id
		}).$promise.then(function(item) {
			item.productTypes = _.map(item.productTypes,"_id")
			$scope.item = item;
			fetchDeps()
		})
	}
	function fetchDeps() {
		ProductType.query().$promise.then(function(data){
			data = _.filter(data, function(o){
				return o.active
			})
			$scope.productTypes = _.map(data, function(productType) {
				var existing = _.find($scope.item.productTypes, function(o) {
					return productType._id == o
				})
				productType.selected = (existing) ? true : false
				return productType
			})
		})
		Application.query().$promise.then(function(applications){
			$scope.applications = _.map(applications, function(application){
				var existing = _.find($scope.item.applications, function(a){
					return a._id == application._id
				})
				if (existing){
					application.selected = true
				}
				return application
			})
		})
		var matcherAttributes = [
			"dim1",
			"length",
			"outerDim1",
			"section2OuterDim1",
			"outerDim1Plus2"
		]
		$scope.matcherAttributes = _.map(matcherAttributes, function(matcherAttribute){
			var existing = _.find($scope.item.matcherAttributes, function(o){
				return o == matcherAttribute
			})
			return {
				name: matcherAttribute,
				selected: (existing) ? true : false
			}
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v", "$promise", "$resolved");
			payload.productTypes = _.pluck(_.where($scope.productTypes,{selected:true}), "_id")
			payload.applications = _.pluck(_.where($scope.applications,{selected:true}), "_id")
			payload.matcherAttributes = _.pluck(_.where($scope.matcherAttributes,{selected:true}), "name")
			if (!$scope.item._id) {
				ProductSubtype.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully created product sub type.', 0, {}, true);
					$state.go("product-subtype", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				ProductSubtype.update({
					id: $scope.item._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated mod type.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
